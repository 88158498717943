import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store } from './redux/store';

import './styles/global.scss';

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <HelmetProvider context={helmetContext}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </HelmetProvider>
    </StoreProvider>,
);
