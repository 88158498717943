interface ApiGeneratorOptions {
    apiUrl?: string;
    endpoint: string;
    queryParams?: Record<string, string>;
}

export const generateApiUrl = (options: ApiGeneratorOptions): string => {
    const apiUrl = options.apiUrl || process.env.REACT_APP_API_URL;

    const url = new URL(apiUrl + options.endpoint);

    if (options.queryParams) {
        const params = Object.entries(options.queryParams);

        for (let i = 0; i < params.length; i += 1) {
            const [key, value] = params[i];
            url.searchParams.set(key, value);
        }
    }

    return String(url);
};
