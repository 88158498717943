interface RequestInitExtraOptions extends RequestInit {
    disableContentType?: boolean;
}

export const fetchUrl = async (url: string, options?: RequestInitExtraOptions): Promise<Response> => {
    const optionsWithHeader: RequestInit = {
        ...options,
        headers: {
            Accept: 'application/vnd.api+json',
            ...(!options?.disableContentType && { 'Content-Type': 'application/vnd.api+json' }),
            ...options?.headers,
        },
    };

    return fetch(url, optionsWithHeader);
};
