import React, { FC, ReactElement } from 'react';

import { NewsItem } from '../../compositions';
import { Article } from '../../entities/Article/Article';

interface NewsItemContainerProps {
    article?: Article;
    showAuthor: boolean;
    className?: string;
    isLoading: boolean;
}

const NewsItemContainer: FC<NewsItemContainerProps> = ({
    article,
    showAuthor,
    className = '',
    isLoading,
}): ReactElement => (
    <div className={`news-item-container ${className}`}>
        {isLoading && <div />}
        {!isLoading && !article && <p>Geen artikel gevonden</p>}
        {article && <NewsItem article={article} showAuthor={showAuthor} />}
    </div>
);

export default NewsItemContainer;
