export const splitArrayModularTwo = <T>(inputArray: T[]): [T[], T[]] => {
    const oddArray: T[] = [];
    const evenArray: T[] = [];

    for (let i = 0; i < inputArray.length; i += 1) {
        if (i % 2) {
            oddArray.push(inputArray[i]);
        } else {
            evenArray.push(inputArray[i]);
        }
    }

    return [evenArray, oddArray];
};
