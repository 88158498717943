import { transformToAuthor } from '../Author/AuthorTransformers';
import { transformToImage } from '../Image/ImageTransformers';
import { Article, ArticleResource } from './Article';

export const transformToArticle = (articleResource: ArticleResource): Article => ({
    id: articleResource.id,
    slug: articleResource.slug,
    title: articleResource.title,
    image: transformToImage(articleResource.hero_image),
    author: articleResource.author_name ? transformToAuthor({ name: articleResource.author_name, url: articleResource.author_image }) : undefined,
    highlights: articleResource.highlight_bullets,
});
