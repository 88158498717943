import React, { FC, ReactElement, useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { NewsItemContainer } from '../../containers';
import { getArticles } from '../../redux/articles/articlesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedArticleDetailContainerProps {
    className?: string;
}

const ConnectedArticleDetailContainer: FC<ConnectedArticleDetailContainerProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();
    const { id } = useParams<{ id: string }>();

    const [searchParams] = useSearchParams();
    const showAuthor = searchParams.has('showAuthor');

    const { articles, isLoading } = useTypedSelector(state => state.articlesReducer);

    useEffect((): void => {
        if (articles.length === 0) dispatch(getArticles());
    }, [articles]);

    const selectedArticle = articles.find(article => article.id === Number(id));

    return (
        <NewsItemContainer
            article={selectedArticle}
            showAuthor={showAuthor}
            className={className}
            isLoading={isLoading}
        />
    );
};

export default ConnectedArticleDetailContainer;
