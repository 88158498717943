import React, { CSSProperties, FC, ReactElement } from 'react';

import { initialPresentationDelay } from '../../containers/VideowallContainer/VideowallContainer';

import './BlockSlider.scss';

interface BlockSliderProps {
    blockSliderDelays: number[];
    className?: string;
}

const BlockSlider: FC<BlockSliderProps> = ({
    blockSliderDelays,
    className = '',
}): ReactElement => {
    const slideInDuration = 2;

    return (
        <div className={`block-slider ${className}`}>
            {blockSliderDelays.map((blockDelay, index) => {
                const cssVariables = {
                    '--initial-slide-delay': `${blockDelay + initialPresentationDelay}s`,
                    '--slide-duration': `${slideInDuration - blockDelay}s`,
                } as CSSProperties;

                return (
                    <div
                        key={`${index}-${blockDelay}`}
                        style={cssVariables}
                        className="block-slider__item"
                    />
                );
            })}
        </div>
    );
};

export default BlockSlider;
