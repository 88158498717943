import { getArticlesApiCall } from '../../entities/Article/ArticleService';
import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { TypedDispatch } from '../store';
import { setArticles, setError, setIsLoading } from './arcticlesReducer';

export const getArticles = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const response = await getArticlesApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setArticles(response.data));
    } catch (error) {
        console.error('[getArticles]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
