import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Article } from '../../entities/Article/Article';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ArticlesState = AsyncReduxState<{
    articles: Article[];
}>;

const initialState: ArticlesState = {
    ...initialAsyncReduxState,
    articles: [],
};

export const ArticlesSlice = createSlice({
    name: 'articlesReducer',
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string>): ArticlesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ArticlesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setArticles(state, action: PayloadAction<Article[]>): ArticlesState {
            return {
                ...state,
                articles: action.payload,
            };
        },
    },
});

export const {
    setArticles,
    setError,
    setIsLoading,
} = ArticlesSlice.actions;

export default ArticlesSlice.reducer;
