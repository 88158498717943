import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedArticleDetailContainer } from '../../connectors';

interface ArticleDetailPageProps {
    className?: string;
}

const ArticleDetailPage: FC<ArticleDetailPageProps> = ({ className }): ReactElement => (
    <Page>
        <ConnectedArticleDetailContainer className={className} />
    </Page>
);

export default ArticleDetailPage;
