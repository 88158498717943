import React, { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedVideowallContainer } from '../../connectors';

interface VideowallPageProps {
    className?: string;
}

const VideowallPage: FC<VideowallPageProps> = (): ReactElement => (
    <Page>
        <ConnectedVideowallContainer />
    </Page>
);

export default VideowallPage;
