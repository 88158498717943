import { Route, Routes } from 'react-router-dom';

import { ArticleDetailPage, VideowallPage } from './pages';

const App = () => (
    <Routes>
        <Route path="/" element={<VideowallPage />} />
        <Route path="/news/:id" element={<ArticleDetailPage />} />
    </Routes>
);

export default App;
