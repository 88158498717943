import enLanguage from '../translation/en.json';

export enum Locale {
    en = 'en',
}

export interface Language {
    name: string;
    code: Locale;
}

export const languages: Language[] = [
    { name: 'English', code: Locale.en },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locales: Record<Locale, any> = {
    [Locale.en]: enLanguage,
};

const trans = (path: string, params?: Record<string, string | number | undefined>): string => {
    const selectors = path.split('.');
    const paramKeys = Object.keys(params || {});

    const defaultLocale = Locale.en;

    let locale;

    if (typeof window !== 'undefined') {
        locale = localStorage.getItem('locale') as Locale;
    }

    let translation = locales[locale || defaultLocale];

    for (let i = 0; i < selectors.length; i += 1) {
        const nextStep = translation[selectors[i]];

        if (!nextStep) {
            console.error(`No translation found for: ${path}`);
            return path;
        }

        translation = nextStep;
    }

    if (typeof translation !== 'string') {
        console.error(`No translation found for: ${path}`);
        return path;
    }

    if (!params) {
        return translation;
    }

    for (let i = 0; i < paramKeys.length; i += 1) {
        const regex = new RegExp(`:${paramKeys[i]}:`, 'g');

        translation = translation.replace(regex, params[paramKeys[i]]);
    }

    return translation;
};

export default trans;
