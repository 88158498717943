export enum FetchResultType {
    Abort = 'abort',
    Error = 'error',
    Success = 'success',
}

export interface SuccessfulFetchResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
    totalPages?: number;
}

export interface SuccessfulFetchCollectionResult<T> {
    status: number;
    type: FetchResultType.Success;
    data: T;
}

export interface UnsuccessfulFetchResult<E> {
    status?: number;
    type: FetchResultType.Error | FetchResultType.Abort;
    error: E;
}

export type FetchCollectionResult<T, E> = SuccessfulFetchCollectionResult<T> | UnsuccessfulFetchResult<E>;

export const isFetchCollectionResultSuccessful = <T, E>(result: FetchCollectionResult<T, E>): result is SuccessfulFetchCollectionResult<T> => (
    result.type === FetchResultType.Success
);
