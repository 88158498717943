import React, { CSSProperties, FC, ReactElement } from 'react';

import { initialPresentationDelay } from '../../containers/VideowallContainer/VideowallContainer';
import trans from '../../helpers/trans';

import './NewsRoom.scss';

interface NewsRoomProps {
    className?: string;
}

const NewsRoom: FC<NewsRoomProps> = ({ className = '' }): ReactElement => {
    const slideInOutDuration = 2.5;
    const slideInOutDelays = [0.45, 0.55, 0.2];
    const newsRoomBackgroundDuration = 2.375;

    const title = trans('compositions.newsroom.title').split('');
    const subTitleLettersArray = [
        trans('compositions.newsroom.subTitleOne').split(''),
        trans('compositions.newsroom.subTitleTwo').split(''),
        trans('compositions.newsroom.subTitleThree').split(''),
    ];

    const titleDelay = newsRoomBackgroundDuration + initialPresentationDelay - 0.25;
    const linkNewsRoom = `${process.env.REACT_APP_AALBERTS_URL}/progress`;

    const backgroundCssVariables = {
        '--news-room-background-duration': `${newsRoomBackgroundDuration - 0.5}s`,
        '--initial-background-delay': `${initialPresentationDelay}s`,
    } as CSSProperties;

    const linkDelayStyle = {
        '--link-initial-delay': `${titleDelay + 0.5}s`,
    } as CSSProperties;

    return (
        <section className={`news-room ${className}`}>
            <div style={backgroundCssVariables} className="news-room__background" />
            <div className="news-room__block-container">
                {slideInOutDelays.map((slideInOutDelay, index) => {
                    const cssVariables = {
                        '--initial-slide-delay': `${slideInOutDelay + initialPresentationDelay}s`,
                        '--slide-duration': `${slideInOutDuration - slideInOutDelay}s`,
                    } as CSSProperties;

                    return (
                        <div
                            key={`${index}-${slideInOutDelay}`}
                            style={cssVariables}
                            className="news-room__sliding-block"
                        />
                    );
                })}
            </div>
            <div className="news-room__context">
                <h1 className="news-room__title">
                    {title.map((titleLetter, index) => {
                        const cssVariables = {
                            '--title-initial-delay': `${titleDelay}s`,
                            '--title-letter-delay': `${index * 0.0625}s`,
                        } as CSSProperties;

                        return (
                            <span key={`${index}-${titleLetter}`} style={cssVariables} className="news-room__title-span">
                                {titleLetter}
                            </span>
                        );
                    })}
                </h1>
                {subTitleLettersArray.map((subTitleLetters, subTitleIndex) => (
                    <h2 key={subTitleIndex} className="news-room__sub-title">
                        {subTitleLetters.map((letter, index) => {
                            const cssVariables = {
                                '--sub-title-initial-delay': `${titleDelay}s`,
                                '--sub-title-letter-delay': `${index * 0.0625}s`,
                            } as CSSProperties;

                            return (
                                <span key={`${index}-${letter}`} style={cssVariables} className="news-room__sub-title-span">
                                    {letter}
                                </span>
                            );
                        })}
                    </h2>
                ))}
                <div className="news-room__link-wrapper">
                    <a href={linkNewsRoom}>
                        <h1
                            style={linkDelayStyle}
                            className="news-room__link"
                        >
                            {trans('compositions.newsroom.link')}
                        </h1>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default NewsRoom;
