import React, { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';

import { BlockSlider } from '../../components';
import { initialPresentationDelay } from '../../containers/VideowallContainer/VideowallContainer';
import { Article } from '../../entities/Article/Article';
import { splitArrayModularTwo } from '../../helpers/array';
import { useTimeout } from '../../hooks/useTimeOut';
import { NewsSlider } from '../index';

import './NewsFeed.scss';

interface NewsFeedProps {
    articles: Article[];
    className?: string;
}

const NewsFeed: FC<NewsFeedProps> = ({
    articles,
    className = '',
}): ReactElement => {
    const [isBlockSlideDone, setIsBlockSlideDone] = useState<boolean>(false);
    const [leftArticles, rightArticles] = splitArrayModularTwo(articles);
    const blockSlideInDelaysLeft = [1, 0.9, 0];
    const blockSlideInDelaysRight = [1.25, 0.6, 1.1];

    useTimeout(() => {
        setIsBlockSlideDone(true);
    }, 3000 + initialPresentationDelay * 1000);

    const newsFeedClassnames = classNames('news-feed', {
        'news-feed--is-slided': isBlockSlideDone,
    }, className);

    return (
        <section className={newsFeedClassnames}>
            {!isBlockSlideDone ? (
                <div className="news-feed__block-slider">
                    <BlockSlider blockSliderDelays={blockSlideInDelaysLeft} />
                    <BlockSlider blockSliderDelays={blockSlideInDelaysRight} />
                </div>
            ) : (
                <>
                    <NewsSlider articles={leftArticles} className="news-feed__news-slider-one" />
                    <NewsSlider articles={rightArticles} className="news-feed__news-slider-two" />
                </>
            )}
        </section>
    );
};

export default NewsFeed;
