import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import thunk from 'redux-thunk';

import { isProduction } from '../helpers';
import reducers, { Reducers } from './reducers';

export type TypedDispatch = ThunkDispatch<Reducers, unknown, AnyAction>;
export type ReducerGetter = () => Reducers;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Reducers> = useSelector;

export const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk),
    devTools: !isProduction,
});
